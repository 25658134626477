import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const EndfieldGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page endfield'} game="endfield">
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/endfield/categories/category_guides.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Arknights: Endfield Guides</h1>
          <h2>
            Best guides for Arknights: Endfield that will help you understand
            the game better
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Arknights: Endfield Database is currently being created!</h4>
          <p>
            We're waiting for the next CBT to start working on the database.
          </p>
        </div>
      </div>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/arknights-endfield/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
      </Row>
    </DashboardLayout>
  );
};

export default EndfieldGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Arknights: Endfield | Prydwen Institute"
    description="Best guides for Arknights: Endfield that will help you understand the game better."
    game="endfield"
  />
);
